document.addEventListener("DOMContentLoaded", function () {
  new Splide(".splide", {
    type: "loop",
    autoplay: true,
    interval: 6000,
  }).mount();
});

let prevScrollpos = window.pageYOffset;
const headerHeight = document.querySelector("header").clientHeight;
const header = document.querySelector(".main-head");

//Hide nav function
document.addEventListener("scroll", function () {
  const currentScrollPos = window.pageYOffset;

  if (currentScrollPos == 0) {
    header.style.backgroundColor = "rgba(255, 255, 255, 0.8)";
  } else {
    header.style.backgroundColor = "rgba(255, 255, 255, 1)";
  }

  if (prevScrollpos > currentScrollPos) {
    document.querySelector("header").style.top = "0";
  } else {
    document.querySelector("header").style.top = `-${headerHeight}px`;
  }
  prevScrollpos = currentScrollPos;
});

// Modal  functions
const openButton = document.querySelectorAll(".open-popUp");
const closeButton = document.querySelectorAll(".close-popUp");
const popUp = document.querySelectorAll(".content-popup");

openButton.forEach((button, index) => {
  button.addEventListener("click", () => {
    openPopUp(index);
  });
});

closeButton.forEach((button, index) => {
  button.addEventListener("click", () => {
    closePopUp(index);
  });
});

window.addEventListener("keydown", (e) => {
  if (e.key === "Escape") {
    popUp.forEach((popUp) => {
      popUp.classList.remove("active");
    });
  }
});

function openPopUp(index) {
  popUp[index].classList.add("active");
}

function closePopUp(index) {
  popUp[index].classList.remove("active");
}
